module.exports = function (time) {
    let timeStr = '';
    if (time == null) {
        timeStr = '-';
    } else if (time <= 3600) {
        timeStr = Math.ceil(time / 60) + '分钟';
    } else {
        timeStr = Math.ceil(time / 3600) + '小时';
    }
    return timeStr;
}