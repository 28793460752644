import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Axios from 'axios';
import {format} from 'silly-datetime';
import qs from "qs";
import formatTime from "./utils/formatTime"


const Login = () => import("@/components/Login");
const Register = () => import("@/components/Register");
const ChangePassword = () => import("@/components/ChangePassword");
const Home = () => import("@/components/Home");
const Overall = () => import("@/components/Overall");
const TaskList = () => import("@/components/TaskList");
const UserList = () => import("@/components/UserList");
const WordCloud = () => import("@/components/WordCloud");
const TaskDetail = () => import("@/components/TaskDetail");
const TaskEditorKeyword = () => import("@/components/TaskEditorKeyword");
const TaskEditorComment = () => import("@/components/TaskEditorComment");
const Statistics = () => import("@/components/Statistics");
const InviteCode = () => import("@/components/InviteCode");
const Manual = () => import("@/components/Manual");
const UserCenter = () => import("@/components/UserCenter");
const LimitList = () => import("@/components/LimitList");
const SystemConfig = () => import("@/components/SystemConfig");

Vue.config.productionTip = false
Vue.prototype.const = {
    // baseURL: 'http://localhost:8080'
    baseURL: 'https://tools.eshyp.com'
}

Vue.use(VueRouter);
Vue.use(ElementUI);

let axios = Axios.create({
    // baseURL: 'http://localhost:8080'
    baseURL: 'https://tools.eshyp.com'
});
axios.interceptors.request.use(config => {
    const token = localStorage.getItem("token");
    if (token) {
        config.headers.token = token;
    }
    return config;
}, err => {
    return Promise.reject(err);
})

axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8';
axios.defaults.transformRequest = data => qs.stringify(data);

axios.interceptors.response.use(response => {
    switch (response.data.code) {
        case 0:
            return response.data.data;
        case 1:
            home.$message.error(response.data.msg);
            return Promise.reject();
        case 2:
            router.push('/login');
            return Promise.reject();
    }
}, err => {
    home.$message.error("系统故障请稍后再试");
    return Promise.reject(err);
})

Vue.prototype.axios = axios;
Vue.prototype.formatdate = format;
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
const router = new VueRouter({
    mode: 'history',
    routes: [
        {path: '/', redirect: '/login'},
        {path: '/login', component: Login},
        {path: '/register', component: Register},
        {
            path: '/home', component: Home, children: [
                {path: 'task/list', component: TaskList},
                {path: 'task/keyword', component: TaskEditorKeyword},
                {path: 'task/comment', component: TaskEditorComment},
                {path: 'task/detail', component: TaskDetail},
                {path: 'user/list', component: UserList},
                {path: 'code/list', component: InviteCode},
                {path: 'word-cloud', component: WordCloud},
                {path: 'overall', component: Overall},
                {path: 'statistics', component: Statistics},
                {path: 'change-password', component: ChangePassword},
                {path: 'manual', component: Manual},
                {path: 'user-center', component: UserCenter},
                {path: 'limit/list', component: LimitList},
                {path: 'system-config', component: SystemConfig},
            ]
        }
    ]
});
Vue.prototype.formatTime = formatTime;

let home = new Vue({
    render: h => h(App),
    router
}).$mount('#app')
